import {BrowserRouter as Router , Routes , Route} from 'react-router-dom';
import Home from './Pages/Home';
import PageTwo from './Pages/PageTwo';
import HowWorks from './Pages/HowWorks';
import WorkWithUs from './Pages/WorkWithUs';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import Technology from './Pages/Technology';
import AboutUs from './Pages/AboutUs';
import News from './Pages/News';
import AA from './Pages/AA';
import NewsDetail from './Pages/NewsDetail';
import Tech from './Pages/Tech';
import Java from './Pages/Java';
import AWS from './Pages/AWS';
import MicrosoftAzure from './Pages/MicrosoftAzure';
import ReactJs from './Pages/ReactJs';
import AngularJS from './Pages/AngularJS';
import Dotnet from './Pages/Dotnet';
import Mulesoft from './Pages/Mulesoft';
import DataScience from './Pages/DataScience';
import CloudComputing from './Pages/CloudComputing';
import Cybersecurity from './Pages/Cybersecurity';
import BlockChain from './Pages/BlockChain';
import PowerBI from './Pages/PowerBI';
import Tableau from './Pages/Tableau';
import Python from './Pages/Python';
import Hadoop from './Pages/Hadoop';
import PowerApps from './Pages/PowerApps';
import Salesforce from './Pages/Salesforce';


function App() {
  return (
  <>
     <Router>
   
   <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/pagetwo" element={<PageTwo/>}/>
    <Route path="/howitwork" element={<HowWorks/>}/>
    <Route path="/workwithus" element={<WorkWithUs/>}/>

    <Route path="/blog" element={<Blog/>}/>
    <Route path="/blog/:blogId" element={<BlogDetail/>}/>
    <Route path="/tech" element={<Technology/>}/>

    <Route path="/about" element={<AboutUs/>}/>
    <Route path="/news" element={<News/>}/>
    <Route path="/news/:blogId" element={<NewsDetail/>}/>

    <Route path="/devops" element={<Tech/>}/>
    <Route path="/java" element={<Java/>}/>

    <Route path="/aws" element={<AWS/>}/>
    <Route path="/azure" element={<MicrosoftAzure/>}/>
    <Route path="/react" element={<ReactJs/>}/>
    <Route path="/angular" element={<AngularJS/>}/>
    <Route path="/dotnet" element={<Dotnet/>}/>
    <Route path="/mulesoft" element={<Mulesoft/>}/>
    <Route path="/datascience" element={<DataScience/>}/>
    <Route path="/cloud" element={<CloudComputing/>}/>

    <Route path="/cybersecurity" element={<Cybersecurity/>}/>
    <Route path="/blockchain" element={<BlockChain/>}/>
    <Route path="/powerbi" element={<PowerBI/>}/>
    <Route path="/tableau" element={<Tableau/>}/>
    <Route path="/python" element={<Python/>}/>
    <Route path="/hadoop" element={<Hadoop/>}/>
    <Route path="/powerapp" element={<PowerApps/>}/>
    <Route path="/salesforce" element={<Salesforce/>}/>
   </Routes>

 </Router>
  </>
  );
}

export default App;

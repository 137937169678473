import React from 'react'
import logo from '../Assets/NEWlogo-removebg-preview.png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
    <div className='footer-top'>
    <div className='f1'>
    <div className="logo">
    <img className="kslogo" src={logo}/>


          
          </div>
          <br/>
  
    <br/>
    <p>KS Training: Empowering tech success. Expert courses, global connections, and career transformation. Thrive in tech—join us!</p>
    <br/>
    <h4>Sign up for Our Newsletter</h4>
    <br/>
   <div className='footerinput'> <input placeholder='Enter your email' /><button>Sign Up</button></div>

    </div>
    <div className='f2'>
      <ul>Pages
        <li>Plateform</li>
        <li>Solution</li>
        <li>Resources</li>
        <li>Our Customers</li>
        <li>Event</li>

      </ul>
    </div>
    <div className='f2'>
      <ul>Our Courses
        <li>DevOps</li>
        <li>Java</li>
        <li>AWS</li>
        <li>Azure</li>
        <li>ReactJS</li>
        <li>AngularJS</li>
        <li>DotNet</li>
        <li>MuleSoft</li>
        <li> Data Science</li>


      </ul>
    </div>
    <div className='f2'>
      <ul>Our Courses
        <li>Cloud</li>
        <li> Cyber Security</li>
        <li> Block Chain</li>
        <li>PowerBI</li>
        <li>Tableau</li>
        <li>Python</li>
        <li>Hadoop</li>
        <li>Power App</li>
        
      </ul>
    </div>
    <div className='f3'>
      <h5>Registration for sponsorship & collaboration</h5>
    <br/>  <button>REGISTER</button>
    <br/> 
    <br/> 
      <h3>Contact Info</h3>
      <br/> 
      <h2>+91-7569320951</h2>
      <br/> 
      <h3>Follow Us</h3>
      <br/> 
      <div className='socialicon' style={{color:'white'}}>
      <i class="bi bi-instagram"></i>
      <i class="bi bi-facebook"></i>
      <i class="bi bi-linkedin"></i>
      <i class="bi bi-twitter"></i>


      </div>
    </div>



    </div>
    <div className='footer-bottom' style={{color:'white'}}>
   <p>© KS Training All rights Reserved. Designed & Developed by <Link style={{color:'white', textDecoration:'none' , fontWeight:'700'}} to='https://www.strixdigital.in/'>Strix Digital</Link></p>

    </div>

    </footer>
  )
}

export default Footer
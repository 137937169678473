import React from 'react'
import about from '../Assets/job-support.jpg'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/2150866277.jpg'

function AboutUs() {
  return (
    <>
    <Navbar/>
      <div className='workwithus'>
    <h1>Our Story</h1>


    </div>
        {/* <div className='about'>
<h3>
KS Training Institute assists you with Job Support, Project Development and Project Support. As a result, you won’t have to worry unnecessarily now. We have experts in all fields of technology on hand. We are an India-based Job Support consulting firm. Hence, We have been assisting many professionals for the past eight years around the globe. Our professionals provide Job Support/Project Development and Support whos works IT professionals in the United States, the United Kingdom, and Canada, with additional 100+ nations on our list and other countries.

Hire us to assist you if you are having technical difficulties at work. We have a technical staff that can assist you in resolving technological difficulties at your workplace. Our job support team has over ten years of expertise working with their respective technologies.

We assist IT employees through convenience and online Job Support, Project Development and Project Support in a wide range of technologies. If you're having trouble or need a professional to help you finish your project, KBS Training Institute  is your one-stop solution. Our experts will work with you remotely to address your difficult task requirements in real time.
</h3>

        </div>
        <br/>
      
<div className='abt-img'>
<img src={about}/>
</div>
<br/>
<br/>
<br/> */}


<section class="about-section">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">About KS Training</span>
                            <h2>Your Expert Solution for Global Job Support and Project Assistance in IT</h2>
                        </div>
                        <div class="text">KS Training Institute assists you with Job Support, Project Development and Project Support. As a result, you won’t have to worry unnecessarily now. We have experts in all fields of technology on hand. We are an India-based Job Support consulting firm. Hence, We have been assisting many professionals for the past eight years around the globe. Our professionals provide Job Support/Project Development and Support whos works IT professionals in the United States, the United Kingdom, and Canada, with additional 100+ nations on our list and other countries. Hire us to assist you if you are having technical difficulties at work. We have a technical staff that can assist you in resolving technological difficulties at your workplace. Our job support team has over ten years of expertise working with their respective technologies. We assist IT employees through convenience and online Job Support, Project Development and Project Support in a wide range of technologies. If you're having trouble or need a professional to help you finish your project, KS Training Institute  is your one-stop solution. Our experts will work with you remotely to address your difficult task requirements in real time.</div>
                    
                        <div class="btn-box">
                            <a href="#" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

             
                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    {/* <div class="inner-column wow fadeInLeft">
                      <div class="author-desc">
                        <h2>ABDC</h2>
                        <span>Founder</span>
                      </div>
                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img src={img} alt=""/></a></figure>
                     
                    </div> */}
                    <img src={img}/>
                    <br/>
                    <br/>
                </div>
              
            </div>
            <br/>
            <br/>
            <br/>
           <div class="sec-title">
                            <span class="title">Our Future Goal</span>
                            <h2>We want to lead in innovation & Technology</h2>
                        </div>
          <div class="text">
                We works on UI/UX and functionality as well so that a plugins comes with proper stucture & stunning looks which suits to your web app & website.
              </div>
               <div class="text">
                We take a small toolkit here and ride it well so that it is fit for your use. One who performs well and looks even better.
              </div>
               <div class="text">                
Here we are trying to give you all kinds of technical content, whether it is related to designing or functionality. We are creating content on a lot of languages and will continue to make it free of cost even if you use it without any problem. Which is a very important thing.
              </div>
               <div class="text">
                Here you can also share the content you create, if our technical team likes it, then we will also share it on our blog.
              </div>
               <div class="text">
                
In the end, I would say keep visiting our website and enjoy the quality content.
              </div>
        </div>
    </section>
   <Footer/>

    </>
  )
}

export default AboutUs